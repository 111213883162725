import React, { useRef } from 'react';
import { useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import { MeshBasicMaterial } from 'three/src/materials/MeshBasicMaterial';
import * as THREE from 'three';

function Model(props) {
  const group = useRef();
  const { nodes } = useLoader(GLTFLoader, '/iphone.gltf');
  const texture = useLoader(TextureLoader, '/glass-wallpaper.jpeg');

  if (!nodes) {
    return null; // return null if the nodes are undefined
  }

  const modelName = nodes['Scene'];

  if (!modelName) {
    console.error('Root node not found in GLTF file:', nodes);
    return null; // return null if the root node is not found
  }

  const clonedModel = modelName.clone();

  if (!clonedModel) {
    console.error('Unable to clone root node:', modelName);
    return null; // return null if the root node cannot be cloned
  }

  // Get the aspect ratio of the image
  const aspectRatio = texture.image.width / texture.image.height;
  // Create a plane geometry with the same aspect ratio as the image
  const planeGeometry = new THREE.PlaneGeometry(aspectRatio, 1);

  // Map the image texture onto the plane
  const planeMaterial = new MeshBasicMaterial({ map: texture });

  // Create a mesh using the plane geometry and material
  const planeMesh = new THREE.Mesh(planeGeometry, planeMaterial);

  // Set the position and rotation of the plane mesh
  planeMesh.position.set(2, 2, 2);

  clonedModel.scale.set(0.1, 0.1, 0.1);

  // Add the plane mesh to the group
  clonedModel.add(planeMesh);

  return (
    <group ref={group} {...props}>
      <primitive object={clonedModel} />
      <mesh position={[0, 0, 0.1]}>
        <boxBufferGeometry args={[1.85, 4.05, 0.05]} />
        <meshStandardMaterial map={texture} />
      </mesh>
    </group>
  );
}

export default Model;
