import React, { useState } from 'react';
import { Canvas, useLoader } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import Model from './Model';
import { TextureLoader } from 'three';

function App() {
  const [zoom, setZoom] = useState(1);

  const handleZoom = (event) => {
    setZoom(event.target.value);
  };

  return (
    <>
      <Canvas style={{ background: '#ddd', height: "100vh", width: "100vw" }}>
        <ambientLight />
        {/* <pointLight position={[10, 10, 10]} /> */}
        <Model zoom={zoom} />
        <OrbitControls />
      </Canvas>
    </>
  );
}

export default App;
